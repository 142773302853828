@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap")
  @import
  url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  line-height: 1.15;
  font-family: "Noto Sans", sans-serif;
  background-color: #cdff70;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
